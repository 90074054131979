<template>
    <b-card class="mb-4"
        header-bg-variant="custom"
        no-body
    >
        <template #header>
            <h4 v-html="$t(tKey + 'header')" class="text-white" />
        </template>
        <b-card-body>
            <div v-if="validData">
                <b-table
                    class="small provenance-table"
                    sticky-header
                    small
                    striped                    
                    caption-top
                    :caption="$t(tKey + 'caption')"
                    :items="data"
                    :fields="fields"
                >
                    <template #cell(market)="data">
                        <img class="mr-2" 
                            :src="`https://flagcdn.com/w20/${data.item.countryCode.toLowerCase()}.png`" 
                            width="20"
                            @error="$event.target.src=''"
                        >
                        <span :id="data.item.market" class="ml-1" v-html="data.item.market.length > 10 ? (data.item.market.substr(0, 10) + '...') : data.item.market" />
                        <b-tooltip v-if="data.item.market.length > 10" :target="data.item.market" triggers="hover">
                            {{ data.item.market }}
                        </b-tooltip>
                    </template>
                    <template #cell(overnights)="data">
                        <p class="mb-1" v-html="$utils.formatToFixed(data.item.overnights)" />
                        <p class="mb-1 font-weight-bold" :class="$utils.isPositive(data.item.overnights, data.item.overnigthsBef) ? 'text-success' : 'text-danger'">
                            <font-awesome-icon :icon="$utils.isPositive(data.item.overnights, data.item.overnigthsBef) ? 'angle-up' : 'angle-down'" />
                            {{ $utils.formatToFixed(Math.abs($utils.computeDifference(data.item.overnights, data.item.overnigthsBef))) }}%
                        </p>                    
                    </template>
                </b-table>
           </div>
            <div v-else>
                <h6 v-html="$t(tKey + 'invalidData')" class="light-link" />
            </div>
        </b-card-body>

    </b-card>
</template>

<script>
export default {
    name: 'ProvenanceTable',
    props: {
        data: { type: Array, required: true },
    },
    data() {
        return {
            tKey: 'dashboard.provenance.',
        }
    },
    computed: {
        fields() {
            return [                
                {
                    key: 'market',
                    label: this.$t(this.tKey + 'countries'),
                    sortable: false,
                },
                {
                    key: 'marketShare',
                    label: this.$t(this.tKey + 'share'),
                    formatter: (value) => `${((value ? value : 0) * 100).toFixed(2)}%`,
                    sortable: true,
                },
                {
                    key: 'overnights',
                    label: this.$t(this.tKey + 'overnights'),
                    sortable: true,
                },
                {
                    key: 'arrivals',
                    label: this.$t(this.tKey + 'arrivals'),
                    formatter: (value) => this.$utils.formatToFixed(value ? value : 0),
                    sortable: true,
                },
                {
                    key: 'lOfStay',
                    label: this.$t(this.tKey + 'stay'),
                    formatter: (value) => `${(value ? value : 0).toFixed(2)}`,
                    sortable: true,
                },
            ]
        },
        validData() {
            return this.data[0].overnights >= 0
        },
    },
}
</script>

<style lang="scss">
.provenance-table {
    max-height: 58vh !important;
    & * {
        vertical-align: middle !important;
    }
}
</style>